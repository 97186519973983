<template>
	<div class="company-box">
		<div class="top-box">
			<span class="title">监测公司管理</span>
			<el-button type="primary" size="mini" icon="el-icon-plus" @click="open('add-pop-ups')"></el-button>
		</div>
		<div class="list-box">
			<ul class="heard ul-box">
				<li class="width-s">序号</li>
				<li class="name"></li>
				<li class="width-xl text-center">电子章</li>
				<li class="width-l text-center">检测签名</li>
				<li class="width-l text-center">审核签名</li>
				<li class="width-x text-center">编辑</li>
			</ul>
			<div class="scroll-box">
				<vue-scroll>
					<div class="company-ul" v-for="(i, index) in list" :key="index">
						<ul class="company ul-box">
							<li class="width-s">{{ index + 1 }}</li>
							<li class="names">{{ i.name }}</li>
						</ul>
						<ul class="content ul-box img-ul">
							<li class="width-s"></li>
							<li class="name"></li>
							<li class="width-xl">
								<el-image v-if="i.pn1 != ''" fit="fill" style="width: 150px; height: 150px" :src="i.pn1" :preview-src-list="[i.pn1]"></el-image>
								<el-image v-else style="width: 150px; height: 150px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
							</li>
							<li class="width-l">
								<el-image v-if="i.pn2 != ''" fit="fill" style="width: 145px; height: 70px" :src="i.pn2" :preview-src-list="[i.pn2]"></el-image>
								<el-image v-else style="width: 145px; height: 70px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
							</li>
							<li class="width-l">
								<el-image v-if="i.pn3 != ''" fit="fill" style="width: 145px; height: 70px" :src="i.pn3" :preview-src-list="[i.pn3]"></el-image>
								<el-image v-else style="width: 145px; height: 70px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
							</li>
							<li class="width-x icon-box">
								<i class="el-icon-edit pointer" @click="open('modify', i)"></i>
								<i class="el-icon-delete pointer" @click="deleteCompany(i.id)"></i>
							</li>
						</ul>
					</div>
				</vue-scroll>
			</div>
		</div>
		<!-- 新增公司弹窗 -->
		<div class="enterprise-pop-ups add-pop-ups Mask-box">
			<div class="pop-ups-box box-center">
				<p class="pop-ups-title">
					<span>新增公司</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-pop-ups')"></span>
				</p>
				<div class="pop-ups-content">
					<vue-scroll>
						<div class="input-box">
							<span>公司名称：</span>
							<el-input v-model="add_info.name" placeholder="请输入内容"></el-input>
						</div>
						<!-- <el-divider></el-divider> -->
						<div class="cube-box file-cube-box">
							<div class="cube file-cube">
								<p class="cude-title">
									电子章
									<br />
									<i>*推荐尺寸：440 x 440（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="addFileOne" :configuration="addParameterOne"></upload-img></div>
							</div>
							<div class="cube file-cube border-r-l">
								<p class="cude-title">
									检测签名
									<br />
									<i>*推荐尺寸：145 x 70（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="addFileTwo" :configuration="addParameterTwo"></upload-img></div>
							</div>
							<div class="cube file-cube">
								<p class="cude-title">
									审核签名
									<br />
									<i>*推荐尺寸：145 x 70（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="addFileThree" :configuration="addParameterThree"></upload-img></div>
							</div>
						</div>
						<div class="cube-box but-box"><el-button type="primary" @click="addInfo()">新增</el-button></div>
					</vue-scroll>
				</div>
			</div>
		</div>
		<!-- 公司修改弹窗 -->
		<div class="enterprise-pop-ups modify Mask-box">
			<div class="pop-ups-box box-center">
				<p class="pop-ups-title">
					<span>编辑</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('modify')"></span>
				</p>
				<div class="pop-ups-content">
					<vue-scroll>
						<div class="cube-box input-box">
							<div>
								<span>公司名称：</span>
								<el-input v-model="enterprise_info.name" placeholder="请输入内容"></el-input>
							</div>
							<el-button type="primary" size="mini" @click="modifyList()">修改</el-button>
						</div>
						<div class="cube-box img-cube-box">
							<div class="cube img-cube">
								<p class="cude-title">电子章</p>
								<el-image
									fit="fill"
									v-if="enterprise_info.pn1 != ''"
									style="width: 200px; height: 200px"
									:src="enterprise_info.pn1"
									:preview-src-list="[enterprise_info.pn1]"
								></el-image>
								<el-image v-else style="width: 200px; height: 200px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
								<i class="delete-icon el-icon-delete" @click="imgDelete(1, enterprise_info.namepn1)" v-show="enterprise_info.pn1 != ''"></i>
							</div>
							<div class="cube img-cube border-r-l">
								<p class="cude-title">检测签名</p>
								<el-image
									fit="fill"
									v-if="enterprise_info.pn2 != ''"
									style="width: 145px; height: 70px"
									:src="enterprise_info.pn2"
									:preview-src-list="[enterprise_info.pn2]"
								></el-image>
								<el-image v-else style="width: 145px; height: 70px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
								<i class="delete-icon el-icon-delete" @click="imgDelete(2, enterprise_info.namepn2)" v-show="enterprise_info.pn2 != ''"></i>
							</div>
							<div class="cube img-cube">
								<p class="cude-title">审核签名</p>
								<el-image
									fit="fill"
									v-if="enterprise_info.pn3 != ''"
									style="width: 145px; height: 70px"
									:src="enterprise_info.pn3"
									:preview-src-list="[enterprise_info.pn3]"
								></el-image>
								<el-image v-else style="width: 145px; height: 70px">
									<div slot="error" class="image-slot"><p class="img-tips">未上传</p></div>
								</el-image>
								<i class="delete-icon el-icon-delete" @click="imgDelete(3, enterprise_info.namepn3)" v-show="enterprise_info.pn3 != ''"></i>
							</div>
						</div>
						<!-- <el-divider></el-divider> -->
						<div class="cube-box file-cube-box">
							<div class="cube file-cube">
								<p class="cude-title">
									选择图片
									<br />
									<i>*推荐尺寸：440 x 440（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="fileOne" :configuration="parameterOne"></upload-img></div>
								<el-button type="primary" @click="getimglist(1)">上传图片</el-button>
							</div>
							<div class="cube file-cube border-r-l">
								<p class="cude-title">
									选择图片
									<br />
									<i>*推荐尺寸：145 x 70（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="fileTwo" :configuration="parameterTwo"></upload-img></div>
								<el-button type="primary" @click="getimglist(2)">上传图片</el-button>
							</div>
							<div class="cube file-cube">
								<p class="cude-title">
									选择图片
									<br />
									<i>*推荐尺寸：145 x 70（单位：像素）</i>
									<br />
									<i>*图片格式：PNG</i>
								</p>
								<div class="file-box"><upload-img ref="fileThree" :configuration="parameterThree"></upload-img></div>
								<el-button type="primary" @click="getimglist(3)">上传图片</el-button>
							</div>
						</div>
					</vue-scroll>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import uploadImg from '../../../components/uploadImg.vue';
export default {
	name: '',
	data() {
		return {
			list: [],
			enterprise_info: {
				name: '', //企业名称
				id: null,
				pn1: '',
				pn2: '',
				pn3: '',
				namepn1: '',
				namepn2: '',
				namepn3: ''
			}, //公司信息
			add_info: {
				name: '' //企业名称
			}, //企业信息
			addParameterOne: {
				limit: 1, //最大文件个数
				name: 'add-component-one' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //新增电子章上传组件参数
			addParameterTwo: {
				limit: 1, //最大文件个数
				name: 'add-component-two' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //新增检测签名上传组件参数
			addParameterThree: {
				limit: 1, //最大文件个数
				name: 'add-component-three' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //新增审核签名上传组件参数
			parameterOne: {
				limit: 1, //最大文件个数
				name: 'component-one' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //修改电子章上传组件参数
			parameterTwo: {
				limit: 1, //最大文件个数
				name: 'component-two' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //修改检测签名上传组件参数
			parameterThree: {
				limit: 1, //最大文件个数
				name: 'component-three' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			} //修改审核签名上传组件参数
		};
	},
	created() {
		this.getList();
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取列表 mcp/nml/glist
		getList() {
			var data = {
				loading: false
			};
			this.axios.post('mcp/nml/glist', data).then(res => {
				this.list = [];
				if (res.status) {
					var len = res.data.length;
					for (let i = 0; i < len; i++) {
						let item = res.data[i];
						for (let key in item) {
							let n = item;
							if (n[key] != '') {
								n['name' + key] = n[key];
								if (key.indexOf('pn') != -1) {
									this.getImgSrc(n[key]).then(res => {
										this.list[i][key] = res;
										this.$forceUpdate();
									});
								}
							}
						}
						this.list.push(item);
					}
				}
			});
		},
		// 添加公司 mcp/add
		addInfo() {
			var data = new FormData();
			var file1 = this.$refs.addFileOne.getFile();
			var file2 = this.$refs.addFileTwo.getFile();
			var file3 = this.$refs.addFileThree.getFile();
			if (this.add_info.name == '') {
				this.$message({
					showClose: true,
					message: '请先填写公司名',
					type: 'warning'
				});
				return;
			}
			// else if (file1.length == 0 || file2.length == 0 || file3.length == 0) {
			// 	this.$message({
			// showClose: true,
			// 		message: '请先选择图片',
			// 		type: 'warning'
			// 	});
			// 	return;
			// }
			data.append('name', this.add_info.name);
			if (file1.length != 0) {
				data.append('file1', file1[0].raw);
			}

			if (file1.length != 0) {
				data.append('file2', file2[0].raw);
			}

			if (file1.length != 0) {
				data.append('file3', file3[0].raw);
			}
			this.axios.post('mcp/add', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '新增成功！',
						type: 'success'
					});
					this.getList();
					this.close('add-pop-ups');
				}
			});
		},
		// 修改公司名称 mcp/modify
		modifyList(val) {
			if (this.enterprise_info.name == '') {
				this.$message({
					showClose: true,
					message: '请先填写公司名',
					type: 'warning'
				});
				return;
			}
			var data = {
				name: this.enterprise_info.name,
				id: this.enterprise_info.id
			};

			this.axios.post('mcp/modify', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功！',
						type: 'success'
					});
					this.getList();
					this.close('modify');
				}
			});
		},

		// 删除公司 mcp/delete
		deleteCompany(id) {
			this.axios.post('mcp/delete', { id }).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '删除成功！',
						type: 'success'
					});
					this.getList();
				}
			});
		},
		// 修改里面的上传图片 mcp/upcp
		modifyImg(data, imgFile) {
			this.axios.post('mcp/upcp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '修改成功！',
						type: 'success'
					});
					this.enterprise_info['pn' + imgFile.id] = imgFile.file.url;
					this.getList();
				}
			});
		},
		// 获取图片上传数据
		getimglist(val) {
			var data = new FormData();
			data.append('id', this.enterprise_info.id);
			var imgFile = {
				id: val
			};
			if (val == 1) {
				var file = this.$refs.fileOne.getFile();
				imgFile.file = file[0];
				// // console.log(file)
				if (file.length != 0) {
					data.append('file1', file[0].raw);
				} else {
					this.$message({
						showClose: true,
						message: '请先选择图片',
						type: 'warning'
					});
					return;
				}
			} else if (val == 2) {
				var file = this.$refs.fileTwo.getFile();
				imgFile.file = file[0];
				if (file.length != 0) {
					data.append('file2', file[0].raw);
				} else {
					this.$message({
						showClose: true,
						message: '请先选择图片',
						type: 'warning'
					});
					return;
				}
			} else if (val == 3) {
				var file = this.$refs.fileThree.getFile();
				imgFile.file = file[0];
				if (file.length != 0) {
					data.append('file3', file[0].raw);
				} else {
					this.$message({
						showClose: true,
						message: '请先选择图片',
						type: 'warning'
					});
					return;
				}
			}
			this.modifyImg(data, imgFile);
		},
		// 删除图片
		imgDelete(num, name) {
			var data = {
				id: this.enterprise_info.id,
				type: num,
				name: name
			};
			this.axios.post('mcp/dcp', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '图片删除成功',
						type: 'success'
					});
					this.enterprise_info['pn' + num] = '';
					this.enterprise_info['namepn' + num] = '';
					this.getList();
					this.$forceUpdate();
				}
			});
		},
		// 根据图片名字获取图片地址
		getImgSrc(name) {
			// console.log(name);
			var url = 'mcp/nml/gsp/' + name;
			var data = {
				params: {
					responseType: 'arraybuffer'
				}
			};
			var href = '';
			return this.axios.get(url, data).then(res => {
				const data = res;

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					// data 是返回来的二进制数据
					var blob = new Blob([data]);
					href = window.URL.createObjectURL(blob);
					return href;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof reader.result;
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						}
					};
					return href;
				}
			});
		},

		// 打开弹窗
		open(val, data = {}) {
			$('.' + val).show();
			if (val == 'modify') {
				for (let key in data) {
					this.enterprise_info[key] = data[key];
				}
			}
		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();
			if (val == 'add-pop-ups') {
				for (let key in this.add_info) {
					this.add_info[key] = '';
				}
				this.$refs.addFileOne.clearFile();
				this.$refs.addFileTwo.clearFile();
				this.$refs.addFileThree.clearFile();
			} else if (val == 'modify') {
				this.$refs.fileOne.clearFile();
				this.$refs.fileTwo.clearFile();
				this.$refs.fileThree.clearFile();
				for (let key in this.enterprise_info) {
					if (key != 'id') {
						this.enterprise_info[key] = '';
					} else {
						this.enterprise_info[key] = null;
					}
				}
			}
		}
	},
	components: {
		uploadImg
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.company-box {
	width: 100%;
	height: 100%;
	background-color: #ffffff;

	.el-image {
		::v-deep {
			.image-slot {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				background-color: #f5f7fa;

				.img-tips {
					text-align: center;
				}
			}
		}
	}

	.top-box {
		padding: 10px 20px 10px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-size: 16px;
			font-weight: 600;
		}

		.el-button {
			font-size: 20px;
			padding: 6px 12px;
		}
	}

	.list-box {
		width: 100%;
		height: 100%;

		ul {
			width: 100%;
			display: flex;
			justify-content: flex-start;

			li {
				margin-left: 55px;
				font-size: 15px;
			}
			li:first-child {
				margin-left: 20px;
			}

			.text-center {
				text-align: center;
			}

			.width-s {
				width: 60px;
			}
			.name {
				width: 150px;
				margin-left: 0px;
			}

			.names {
				margin-left: 0px;
			}

			.width-xl {
				width: 150px;
			}
			.width-l {
				width: 145px;
			}
			.width-x {
				width: 100px;
			}

			.icon-box {
				height: 100px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: center;

				i {
					font-size: 20px;
				}
			}
		}

		.heard {
			align-items: center;

			li {
				padding: 8px 0;
				font-size: 14px;
				font-weight: 600;
			}
		}

		.scroll-box {
			height: calc(100% - 90px);

			.company-ul {
				width: 100%;
				border-bottom: thin solid #dcdfe6;

				.company {
					li {
						padding: 6px 0;
					}
					.name {
						// width: calc(100% - 30px);
					}
				}

				.img-ul {
					align-items: center;
				}
			}
		}
	}

	// 弹窗标题
	.pop-ups-title {
		width: 100%;
		height: 35px;
		line-height: 35px;
		font-size: 14px;
		text-align: center;
		background-color: #66b1ff;
		color: #ffffff;
		position: relative;

		.close {
			position: absolute;
			top: 1px;
			right: 15px;
			color: #ffffff;
			font-size: 14px;
		}
	}

	// 图片、公司名称修改新增弹窗
	.enterprise-pop-ups {
		z-index: 10;
		display: none;

		.pop-ups-box {
			// width: 80%;
			width: 58px;
			// height: 620px;
			// height: 60%;
			// min-height: 560px;
			// max-height: 700px;
			min-width: 800px;
			z-index: 1010;

			.pop-ups-content {
				width: 100%;
				height: calc(100% - 35px);
				background-color: #ffffff;

				::v-deep {
					.__view {
						height: 100%;
					}
				}

				.cube-box {
					width: 100%;
					display: flex;
					justify-content: space-between;
					// align-items: center;

					.cube {
						width: 33%;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;
						padding: 15px 0 40px 0;
						position: relative;

						// 删除按钮
						.delete-icon {
							width: 30px;
							height: 30px;
							text-align: center;
							line-height: 30px;
							font-size: 22px;
							color: #ff6b66;
							cursor: pointer;
							position: absolute;
							bottom: 5px;
							right: 8px;
						}

						.delete-icon:hover {
							color: #fff;
							background-color: #ff6b66;
						}

						:v-deep {
							.el-image {
								display: flex;
								justify-content: center;
								align-items: center;

								p {
									font-size: 16px;
								}
							}
						}

						.cude-title {
							width: 100%;
							text-align: center;
							font-size: 13px;
							font-weight: 600;
							padding: 0px 0 10px 0;

							i {
								color: #ff0000;
								font-size: 12px;
								font-weight: 500;
							}
						}

						button {
							margin: 15px 0 20px 0;
						}
					}

					.file-cube {
						padding-bottom: 0;
					}

					.border-r-l {
						border-left: thin solid #dcdfe6;
						border-right: thin solid #dcdfe6;
					}
				}

				.file-cube-box {
					border-top: thin solid #dcdfe6;
				}
			}
		}
	}

	// 新增
	.add-pop-ups {
		.pop-ups-box {
			height: 415px;

			.pop-ups-content {
				.input-box {
					padding: 10px 0 10px 10px;
					font-size: 14px;
				}

				.file-cube {
					padding-bottom: 10px !important;
					border-bottom: thin solid #dcdfe6;
				}

				.but-box {
					justify-content: center;
					margin-top: 15px;
				}
			}
		}
	}
	// 修改
	.modify {
		.pop-ups-box {
			height: 695px;

			.pop-ups-content {
				.input-box {
					width: calc(100% - 20px);
					padding: 10px;
					font-size: 14px;
					border-bottom: thin solid #dcdfe6;
					justify-content: flex-start;

					button {
						height: 30px;
						flex-shrink: 0;
						margin-left: 20px;
					}
				}
			}
		}
	}
}
</style>
